{
	"side-bar-panel-control":{
        "account":"Información de mi cuenta",
        "profile":"Mi perfil",
        "password":"Contraseña",
        "pref":"Preferencias",
        "config":"Config. de Webhook",
		"account-page":{
			"name":"Nombre completo",
			"email":"Correo de contacto",
			"header-2":"Información de tu empresa",
			"company":"Empresa",
			"type":"Tipo de usuario",
			"ID":"ID de empleado",
			"header-3":"Código master",
			"code":"Tu código de hoy",
			"btn-generate":"Generar nuevo código",
			"btn":"Guardar cambios"
		},
        "password-page" : {
			"txt-1":"Si quieres cambiar tu contraseña, ingresa la actual para empezar el proceso.",
			"current":"Contraseña actual",
			"new":"Nueva contraseña",
			"confirm":"Confirmar contraseña"
		},
        "preferences-page":{
			"dark-mode":"Modo oscuro",
			"light-mode":"Modo claro",
			"language":"Idioma",
			"es":"Español",
			"en":"Inglés"
		}
    },
	"buttons":{
		"resetChanges":"Restablecer cambios",
		"saveInformation":"Guardar información",
		"saveChanges":"Guardar información"
	},
	"accountSettingsPage":{
		"title":"Mi cuenta",
		"switchContent":{
			"perfil" 		: "Perfil",
			"notifycations" : "Notificaciones",
			"preferences" 	: "Preferencias"
		},
		"perfil":{
			"my-information":{
				"title":"Mi informacion",
				"subtitle":"Gracias a esta información podemos contactarte en caso de ser necesario, asi como para procesos de devolución o aclaraciones.",
				"username":"Nombre de usuario",
				"placeholderUsername":"Nombre del titular",
				"idEmployee":"ID de empleado",
				"placeholderIDEmployee":"1324-5676",
				"email":"Correo electrónico",
				"placeholder":"nombre@ejemplo.com",
				"telephone":"Telefono de contacto",
				"placeholderTelephone":"(81)1625-6549"
			},
			"password":{
				"title":"Contraseña",
				"subtitle":"Ingresa tu contraseña actual para poder crear una nueva.",
				"form":{
					"actual-password":"Contraseña actual",
					"new-password":"Tu nueva contraseña",
					"boxValidation":{
						"title":"Para proteger tu cuenta, asegúrate que tu contraseña tiene:",
						"validation1":"Una mayúscula",
						"validation2":"Un número",
						"validation3":"Un carácter especial",
						"validation4":"Que tenga mínimo 8 caracteres"
					},
					"confirmPassword":"Confirmar tu contraseña",
					"btnUpdatePassword":"Actualizar mi contraseña"
				}
			}
		},
		"notifications":{
			"dashboard-notifications":{
				"title":"Notificaciones en dashboard",
				"subtitle":"Selecciona que tipo de notificaciones quieres recibir. Las notificaciones de sistema no se pueden desactivar.",
				"selects":{
					"title-order":"Ordenes",
					"subtitle-order":"Ordenes recibidas, en ruta de entrega, en locker, recolectadas, expiradas, etc.",
					"title-fulfillment":"Fullfillment",
					"subtitle-fulfillment":"Productos recibidos en almacén, reingresos, bajas y nulas unidades en stock.",
					"title-tickets":"Tickets de pago",
					"subtitle-tickets":"Tickets nuevos, fechas de vencimiento, pagos aprobados o rechazados."
				}
			},
			"dashboard-email":{
				"title":"Notificaciones por correo electronico",
				"subtitle":"Estas notificaciones se envían cada que algo importante pasa con tu cuenta. Selecciona que tipo de correos quieres recibir.",
				"selects":{
					"title-order":"Ordenes",
					"subtitle-order":"Ordenes recibidas, en ruta de entrega, en locker, recolectadas, expiradas, etc.",
					"title-fulfillment":"Fullfillment",
					"subtitle-fulfillment":"Productos recibidos en almacén, reingresos, bajas y nulas unidades en stock.",
					"title-tickets":"Tickets de pago",
					"subtitle-tickets":"Tickets nuevos, fechas de vencimiento, pagos aprobados o rechazados.",
					"title-surveys":"Encuestas",
					"subtitle-surveys":"Estas encuestas sirven para que podamos mejorar y ofrecerte una mejor experiencia."
				}
			}
		},
		"preferences":{
			"appearance":{
				"title":"Apariencia",
				"subtitle":"Configura la interfaz a tu gusto, selecciona el color principal o el modo en el que se encuentra.",
				"color-primary":{
					"title": "Color principal",
					"subtitle":"Este color se verá en botones o bordes."
				},
				"mode":{
					"title":"Modo",
					"light":"Claro",
					"dark":"Oscuro"
				}
			},
			"language":{
				"title":"Idioma",
				"subtitle":"Configura la apariencia de tu aplicacion a tu gusto, cambia su color principal o el modo, ya sea claro u oscuro.",
				"lang":{
					"es":"Español",
					"en":"Ingles"
				}
			}
		}
	},
	"loginPage":{
		"title"		:"Bienvenido",
		"subtitle"	:"Por favor, ingresa la siguiente información para continuar.",
		"form"		:{
			"email"				:"Correo electrónico",
			"password"			:"Contraseña",
			"checkbox"			:"Recuerda mi información",
			"forgetPassword"	:"Olvidé mi contraseña",
			"btnLogin"			:"Iniciar sesión",
			"footer"			:"¿Eres nuevo en fullfillment?",
			"footerLink"		:"Nosotros te ayudamos"
		}
	},
	"createNewPassword":{
		"forgotPasswordForm":{
			"title":"Olvidé mi contraseña",
			"subtitle":"No te preocupes, le puede pasar a cualquiera. Escribe el correo asociado a tu cuenta y te diremos como puedes recuperarla.",
			"email":"Correo electrónico",
			"recoverPassword":"Recuperar mi contraseña",
			"buttonThrowback":"Volver a la pantalla principal"
		},
		"title":"Crea una nueva contraseña",
		"subtitle":"Completa los siguientes campos y recupera tu cuenta para que puedas seguir enviando tus productos.",
		"form":{
			"labelNewPassword":"Tu nueva contraseña",
			"boxValidation":{
				"title":"Para proteger tu cuenta, asegúrate que tu contraseña tiene:",
				"validation1":"Una mayúscula",
				"validation2":"Un número",
				"validation3":"Un carácter especial",
				"validation4":"Que tenga mínimo 8 caracteres"
			},
			"confirmPassword":"Confirmar tu contraseña",
			"btnUpdatePassword":"Actualizar mi contraseña"
		},
		"passwordChanged":{
			"title":"¡Todo listo!",
			"subtitle":"Sabíamos que estarías de vuelta. Has recuperado tu cuenta y ahora ya puedes seguir administrando tu red logistica con nosotros."
		},
		"passwordNotChanged":{
			"title":"¡Oh hubo un error!",
			"subtitle":"Lamentamos lo que ha pasado, podrias rehacer el proceso para el cambio de contraseña"
		},
		"oneStepMore":{
			"title":"Solo un paso más...",
			"subtitle-1":"Si el correo",
			"subtitle-2":"está asociado a una cuenta recibirás un correo con los siguientes pasos. Asegúrate de revisar tu carpeta de correos no deseados o spam.",
			"paragraph-1":"¿No estas seguro del correo que ingresaste?",
			"paragraph-2":"Intenta con uno diferente."
		},
		"buttons":{
			"throwback-login":"Ir a la pantalla principal",
			"updatePassword":"Actualizar mi contraseña",
			"sendEmail":"Reenviar correo",
			"returnBackLogin":"Volver a la pantalla principal"
		}
	},
	"sidebarComponent": {
		"settings":{
			"title":"Ajustes",
			"languages": {
				"title": "Idioma",
				"es": "Español",
				"en": "Inglés"
			},
			"appearance": {
				"title": "Apariencia",
				"light": "Claro",
				"dark": "Oscuro"
			}
		}
	},
	"lockerPage":{
		"title":"Red logística, Lockers",
		"cards":{
			"cardsOrders":{
				"card-1":{
					"title":"Ordenes entregadas",
					"tooltip":"Cantidad de entregas totales desde el inicio de uso del sistema.",
					"footer":"Información no disponible"
				},
				"card-2":{
					"title":"Ordenes recolectadas",
					"tooltip":"Cantidad de recolecciones totales desde el inicio de uso del sistema.",
					"footer":"vs mes pasado"
				},
				"card-3":{
					"title":"Ordenes en locker",
					"tooltip":"Cantidad de paquetes que actualmente se encuentran en todos nuestros lockers.",
					"footer":"vs mes pasado"
				}
			},
			"mapCard":{
				"title":"Ubicaciones"
			}
		},
		"location":"Ubicacion",
		"input-search":"Buscar...",
		"filters":"Filtros",
		"export":"Exportar",
		"table":{
			"alias":"Alias del locker",
			"location":"Ubicación",
			"totalDeliveries":"Entregas totales",
			"doors":"Puertas",
			"doorsAvailable":"Puertas disponibles",
			"status":"Estatus"
		},
		"statusLocker":{
			"1":"Activo",
			"2":"Inactivo"
		}
	},
	"utcPage":{
		"title":"Red logística, UTC",
		"cards":{
			"bigCard":{
				"title":"Tiendas totales"
			},
			"cardsUTC":{
				"card-1":{
					"title":"UTC activos",
					"tooltip":"Tooltip de UTC activos",
					"footer":"Información no disponible"
				},
				"card-2":{
					"title":"UTC inactivos",
					"tooltip":"Tooltip de UTC activos",
					"footer":"Información no disponible"
				}
			},
			"cardsOrders":{
				"card-1":{
					"title":"Ordenes completadas",
					"tooltip":"Tooltip de Ordenes completadas",
					"footer":"Información no disponible"
				},
				"card-2":{
					"title":"Ordenes en proceso",
					"tooltip":"Tooltip de Ordenes en proceso",
					"footer":"vs mes pasado"
				},
				"card-3":{
					"title":"Ordenes expiradas",
					"tooltip":"Tooltip de Ordenes expiradas",
					"footer":"vs mes pasado"
				},
				"card-4":{
					"title":"Ordenes devueltas",
					"tooltip":"Tooltip de Ordennes devueltas",
					"footer":"vs mes pasado"
				}
			},
			"mapCard":{
				"title":"Ubicaciones"
			}
		},
		"table":{
			"alias":"Alias de UTC",
			"store":"Tienda",
			"referenceCode":"CR",
			"openingHours":"Horario de atención",
			"spacesAvailable":"Espacios disponibles",
			"systemStatus":"Estatus del sistema"
		},
		"components":{
			"status":{
				"active":"Activo",
				"inactive":"Inactivo"
			}
		},
		"overlayNoContent":{
			"title":"Sin resultados",
			"subtitle":"Parece que no pudimos encontrar ningún elemento que coincida con tu búsqueda."
		},
		"overlayLoading":{
			"title":"Cargando"
		}
	},
	"utcDetailPage":{
		"breadcrumb":{

		},
		"contentSwitcher":{
			"resumen":"Resumen",
			"actividad":"Actividad"
		},
		"cards":{
			"cardsUTC":{
				"card-1":{
					"title":"Prom. uso vs capacidad",
					"tooltip":"Tooltip de Prom. uso vs capacidad",
					"footer":"vs mes pasado"
				},
				"card-2":{
					"title":"OXXO a OXXO, Local",
					"tooltip":"Tooltip de OXXO a OXXO, Local",
					"footer":"vs mes pasado"
				},
				"card-3":{
					"title":"OXXO a OXXO, Nacional",
					"tooltip":"Tooltip de OXXO a OXXO, Nacional",
					"footer":"vs mes pasado"
				},
				"card-4":{
					"title":"Socios PUDO",
					"tooltip":"Tooltip de Socios PUDO",
					"footer":"vs mes pasado"
				}
			},
			"cardsOrders":{
				"card-1":{
					"title":"Ordenes completadas",
					"tooltip":"Tooltip de ordenes completadas",
					"footer":"vs mes pasado"
				},
				"card-2":{
					"title":"Ordenes expiradas",
					"tooltip":"Tooltip de ordenes expiradas",
					"footer":"vs mes pasado"
				},
				"card-3":{
					"title":"Ordenes devueltas",
					"tooltip":"Tooltip de ordenes devueltas",
					"footer":"vs mes pasado"
				},
				"card-4":{
					"title":"Trafico total por unidad",
					"tooltip":"Tooltip de Trafico total por unidad",
					"footer":"vs mes pasado"
				}
			},
			"cardMap":{
				"title":"Ubicacion"
			}
		},
		"table":{
			"contentSwitcher":{
				"todas":"Todas las ordenes",
				"proceso":"En proceso",
				"expiradas":"Expiradas",
				"agendadas":"Agendadas",
				"completadas":"Completadas",
				"canceladas":"Canceladas"
			},
			"activity":{
				"orderNumber":"Número de orden",
				"event":"Evento",
				"dateEvent":"Fecha de evento",
				"description":"Descripcion"
			},
			"general":{
				"orderNumber":"Número de orden",
				"model":"Modelo",
				"sender":"Remitente",
				"addressee":"Destinatario",
				"origin":"Origen",
				"destination":"Destino",
				"status":"Estatus",
				"last_update":"Ult. actualización"
			},
			"orderNumber":"Número de orden",
			"sender":"Remitente",
			"addressee":"Destinatario",
			"origin":"Origen",
			"destination":"Destino",
			"status":"Estatus",
			"last_update":"Ult. actualización"
		},
		"comparments":{
			"disponible":"Disponible",
			"agendado":"Agendado",
			"expirado":"Expirado",
			"abierto":"Abierto",
			"ocupado":"Ocupado",
			"defectuoso":"Defectuoso"
		}
	},
	"lockerDetailPage":{
		"breadcrumb":{

		},
		"contentSwitcher":{
			"resumen":"Resumen",
			"actividad":"Actividad"
		},
		"cards":{
			"cardsLocker":{
				"card-1":{
					"title":"Puertas pequeñas",
					"tooltip":"Medidas: 8cm de alto,37.5cm de ancho, 55cm de profundidad",
					"footer":"Disponibles"
				},
				"card-2":{
					"title":"Puertas medianas",
					"tooltip":"Medidas: 18cm de alto,37.5cm de ancho, 55cm de profundidad",
					"footer":"Disponibles"
				},
				"card-3":{
					"title":"Puertas grandes",
					"tooltip":"Medidas: 28cm de alto,37.5cm de ancho, 55cm de profundidad",
					"footer":"Disponibles"
				},
				"card-4":{
					"title":"Puertas extra grandes ",
					"tooltip":"Medidas: 47.5cm de alto,37.5cm de ancho, 55cm de profundidad",
					"footer":"Disponibles"
				}
			},
			"cardsOrders":{
				"card-1":{
					"title":"Entregas totales(todo el tiempo)",
					"tooltip":"Tooltip de entregas totales(todo el tiempo)",
					"footer":"vs mes pasado"
				},
				"card-2":{
					"title":"Retornos totales(todo el tiempo)",
					"tooltip":"Tooltip de retornos totales(todo el tiempo)",
					"footer":"vs mes pasado"
				},
				"card-3":{
					"title":"Entregas totales(dia actual)",
					"tooltip":"Tooltip de entregas totales(dia actual)",
					"footer":"vs mes pasado"
				},
				"card-4":{
					"title":"Retornos totales(dia actual)",
					"tooltip":"Tooltip de retornos totales(dia actual)",
					"footer":"vs mes pasado"
				}
			},
			"cardMap":{
				"title":"Ubicacion"
			}
		},
		"table":{
			"contentSwitcher":{
				"todas":"Todas las ordenes",
				"proceso":"En proceso",
				"expiradas":"Expiradas",
				"agendadas":"Agendadas",
				"completadas":"Completadas",
				"canceladas":"Canceladas"
			},
			"activity":{
				"eventId":"ID del evento",
				"event":"Evento",
				"dateEvent":"Fecha de evento",
				"description":"Descripcion"
			},
			"general":{
				"tracking_number":"Número de orden",
				"locker_name":"Locker",
				"email":"Email",
				"door_number":"Puerta",
				"status":"Estatus",
				"date_in_locker":"Fecha de recibido",
				"date_collected":"Fecha de retirado"
			},
			"expired":{
				"tracking_number":"Número de seguimiento",
				"email":"Email",
				"door_number":"Puerta",
				"status":"Estatus",
				"date_in_locker":"Fecha de recibido",
				"date_expired":"Fecha de expiracion"
			},
			"completed":{
				"tracking_number":"Número de seguimiento",
				"locker_name":"Locker",
				"email":"Email",
				"door_number":"Puerta",
				"status":"Estatus",
				"date_in_locker":"Fecha de recibido",
				"date_collected":"Fecha de retirado"
			},
			"schedule":{
				"package":"Paquete",
				"otp":"OTP",
				"email":"Email",
				"door_number":"Puerta",
				"size_compartment":"Tamaño",
				"status":"Estatus",
				"register_date":"Fecha de registro"
			},
			"canceled":{
				"tracking_number":"Número de seguimiento",
				"nameEmploye":"Empleado que cancelo",
				"door_number":"Puerta",
				"status":"Estatus",
				"date_collected":"Fecha de retirado",
				"date_cancel":"Fecha de cancelado",

				"locker_name":"Locker"
			}
		},
		"estatus":{
			"RECOLECTADO":"Recolectado",
			"RETURN": "Devuelto",
			"ENTREGADO":"Entregado",
			"SCHEDULED":"Agendado",
			"PENDIENTE": "Pendiente",
			"EXPIRED":"Expirado",
			"EXTENDED":"Extendido",
			"LOCKER":"En Locker",
			"CANCELED":"Cancelado",
			"CANCELED_LOCKER": "Cancelado En Locker",
			"CANCELED_PICKED": "Cancelado Retirado",
			"PASSWORD_EXPIRADO":"Password Expirado",
			"ACTIVE":"Activo",
			"DESACTIVE":"Desactivo",
			"PackageCollected":"Paquete Colectado",
			"PackageInLocker":"Paquete en Locker",
			"PackageExpired":"Paquete Expirado",
			"Warehouse":"En Almacen",
			"ScheduledDoor":"Puerta Programada",
			"OpenDoor": "Puerta Abierta",
			"CloseDoor": "Puerta Cerrada",
			"FailDoor": "Fallo en Puerta",
			"BusyDoor":"Puerta Ocupada",
			"loginSuccess":"Exito en Login",
			"loginError":"Intento log-in",
			"useMasterCode":"Uso Master Code",
			"logout":"Log-out"
		},
		"comparments":{
			"disponible":"Disponible",
			"agendado":"Agendado",
			"expirado":"Expirado",
			"abierto":"Abierto",
			"ocupado":"Ocupado",
			"defectuoso":"Defectuoso"
		}
	},
	"usersPage":{
		"title":"Usuarios",
		"table":{
			"client_name":"Nombre completo",
			"email":"Email del usuario",
			"active_days":"Dias activo",
			"register_date":"Fecha de registro",
			"expired_date":"Fecha de expiracion de credenciales",
			"status":"Estatus del usuario",
			"actions":"Acción"
		},
		"buttons":{
			"createUser":"Crear Usuario"
		},
		"overlays":{
			"noContent":{
				"title":"No hay nada por aquí...",
				"subtitle":"En esta tabla encontrarás a todos los usuarios e información sobre ellos. "
			}
		},
		"dialogs":{
			"dialogChangeStatusUser":{
				"headerTitle":"¿Estas seguro de que quieres desactivar a este usuario?",
				"subHeaderTitle":"Al desactivar un usuario, este perderá el acceso a al dashboard y no podrá entregar paquetes a ningún locker. Puedes volver a activar a este usuario en cualquier momento.",
				"buttons":{
					"cancel":"Cancelar",
					"desactivate":"Si, quiero desactivarlo"
				}
			},
			"dialogEditUser":{
				"title":"Editar Usuario",
				"editUser":{
					"title":"Agregar usuario",
					"fieldNameCompany":"Nombre de la Empresa",
					"fieldIdEmployee":"ID de empleado",
					"fieldTypeEmployee":"Tipo de Usuario",
					"fieldNameEmployee":"Nombre Completo",
					"fieldEmail":"Email",
					"fieldPassword":"Contraseña",
					"fieldConfirmPassword":"Confirma la Contraseña",
					"btnGeneratePassword":"Generar Contraseña",
					"btnCancel":"Cancelar",
					"btnContinue":"Continuar",
					"errorFieldRequired":"Campo requerido",
					"errorFieldPassword":"La Contraseña No Es Igual",
					"errorFieldEmail": "Asegurate de ingresar un email válido",
					"errorFieldId": "Campo requerido. Debe contener entre 6 y 8 caracteres.",
					"passwordInfo":"La contraseña para el acceso será enviada al correo que proporcionaste.",
					"passwordInfoCarrier":"La contraseña para el acceso será enviada al correo: "
				}
			},
			"dialogAddUser":{
				"title":"Agregar usuario",
				"fields":{
					"typeEmployee":"Tipo de Usuario",
					"email":"Email",
					"nameEmployee":"Nombre Completo",
					"passwordInfo":"La contraseña para el acceso será enviada al correo que proporcionaste."
				},
				"buttons":{
					"cancel":"Cancelar",
					"saveUser":"Guardar Usuario"
				}
			}
		},
		"cards":{
			"card-1":{
				"title":"KPI usuario",
				"tooltip":"Tooltip  de KPI usuarios",
				"footer":"Información no disponible"
			},
			"card-2":{
				"title":"KPI usuario",
				"tooltip":"Tooltip  de KPI usuarios",
				"footer":"Información no disponible"
			},
			"card-3":{
				"title":"KPI usuario",
				"tooltip":"Tooltip  de KPI usuarios",
				"footer":"Información no disponible"
			},
			"card-4":{
				"title":"KPI usuario",
				"tooltip":"Tooltip  de KPI usuarios",
				"footer":"Información no disponible"
			},
			"card-5":{
				"title":"KPI usuario",
				"tooltip":"Tooltip  de KPI usuarios",
				"footer":"Información no disponible"
			},
			"card-6":{
				"title":"KPI usuario",
				"tooltip":"Tooltip  de KPI usuarios",
				"footer":"Información no disponible"
			}
		}
	},
	"clientsPage":{
		"title":"Clientes",
		"cards":{
			"card-1":{
				"title":"Clientes totales",
				"tooltip":"Cantidad total de usuarios desde el inicio del uso del sistema.",
				"footer":"Informacion no disponible"
			},
			"card-2":{
				"title":"Clientes únicos",
				"tooltip":"Clientes que recogen órdenes solo una vez.",
				"footer":"Informacion no disponible"
			},
			"card-3":{
				"title":"Clientes recurrentes",
				"tooltip":"Clientes que recogen órdenes más de dos veces.",
				"footer":"Informacion no disponible"
			}
		},
		"metric":{
			"title":"Volumen total de envíos",
			"store":"Tienda",
			"locker":"Locker",
			"days":{
				"monday":"Lunes",
				"tuesday":"Martes",
				"wednesday":"Miercoles",
				"thursday":"Jueves",
				"friday":"Viernes",
				"saturday":"Sabado",
				"sunday":"Domingo"
			}
		},
		"table":{
			"client_name":"Nombre del cliente",
			"email":"Correo electrónico",
			"viviID":"VIVI ID",
			"paid_packages":"Cantidad de Paquetes",
			"register_date":"Fecha de registro"
		}
	},
	"timePicker":{
		"hour":"Hora",
		"minute":"Minuto",
		"buttons":{
			"erase":"Borrar",
			"cancel":"Cancelar",
			"accept":"Aceptar"
		}
	},
	"storesPage":{
		"title":"Red logística, Tiendas",
		"tables":{
			"createStore":"Crear tienda",
			"createStores":"Crear Tiendas",
			"general":{
				"storeName":"Nombre de\nTienda",
				"storeAlias":"Alias de\nTienda",
				"storeCode":"Codigo de\nTienda",
				"address":"Direccion",
				"latitude":"Latitud",
				"longitude":"Longitud",
				"state":"Estado",
				"city":"Ciudad",
				"referenceCode":"CR",
				"openingHours":"Horario de atención",
				"openening_time":"Hora de\napertura",
				"closing_time":"Hora de\nclausura",
				"packageManaged":"Paquetes \nmanejados",
				"accion":"Accion"
			}
		},
		"dialog":{
			"dialogCreateStore":{
				"title":"Agregar tienda",
				"info":"Ingresa los siguientes detalles para poder dar de alta una tienda. Todos los campos son obligatorios.",
				"location":"Elige la ubicacion de la tienda",
				"fields":{
					"storeName":"Nombre de la tienda",
					"storeAlias":"Alias de tienda",
					"state":"Estado",
					"city":"Plaza",
					"address":"Direccion",
					"latitud":"Latitud",
					"longitud":"Longitud",
					"codeReference":"CR",
					"capacity":"Capacidad del servicio",
					"openingHours":"Horario de atención",
					"storeOpening":"Horario de apertura",
					"storeClosing":"Horario de clausura"
				},
				"buttons":{
					"cancel":"Cancelar",
					"accept":"Aceptar"
				}
			},
			"dialogImportStore":{
				"fields":{
					"store":"Tienda",
					"storeAlias":"Alias de tienda",
					"codeReference":"CR",
					"state":"Estado",
					"plaza":"Plaza",
					"openingHours":"Horario de atención"
				}
			},
			"dialogEditStore": {
				"title": "Editar tienda",
				"location":"Elige la ubicacion de la tienda",
				"fields":{
					"storeName":"Nombre de tienda",
					"storeAlias":"Alias de tienda",
					"state":"Estado",
					"city":"Ciudad",
					"address":"Direccion",
					"latitud":"Latitud",
					"longitud":"Longitud",
					"codeReference":"CR",
					"storeOpening":"Horario de apertura",
					"storeClosing":"Horario de clausura"
				},
				"buttons":{
					"cancel":"Cancelar",
					"accept":"Aceptar"
				}
			},
			"dialogDeleteStore": {
				"title": "¿Estas seguro de que quieres eliminar esta tienda?",
				"delete":"Al eliminar una tienda, es importante que consideres que:\nLos paquetes agendados no podrán ser entregados.",
				"buttons":{
					"cancel":"Cancelar",
					"accept":"Aceptar"
				}
			}
		},
		"buttons":{
			"createStore":"Agregar tienda",
			"importStore":"Importar tiendas"
		}
	},
	"storeDetailPage":{
		"title":"Tiendas",
		"cards":{
			"openingTime":{
				"title":"Horario de apertura"
			},
			"closingTime":{
				"title":"Horario de clausura"
			},
			"location":{
				"title":"Ubicacion"
			}
		},
		"tables":{
			"general":{
				"tracking_number":"Tracking",
				"registration_date":"Fecha de Registro",
				"entry_date":"Fecha de entrada",
				"expiration_date":"Fecha de\nexpiración",
				"package_status_id":"Estatus ID",
				"package_status": "Estatus",
				"volume":"Volumen",
				"type":"Tipo de servicio",
				"company":"Compañía",
				"destination_store":"Tienda destino",
				"id_courier":"Carrier ID",
				"courier_name":"Carrier"
			},
			"couriers":{
				"courier_name":"Carrier",
				"signature_required":"Firmas\nnecesarias",
				"id_status":"ID Estatus"
			}
		}
	},
	"overviewPage":{
		"title":"Bienvenido, ",
		"export":"Exportar",
		"heatMap":{
			"title":"Horario de mayor uso",
			"categories":{
				"d1":"Lunes",
				"d2":"Martes",
				"d3":"Miercoles",
				"d4":"Jueves",
				"d5":"Viernes",
				"d6":"Sabado",
				"d7":"Domingo"
			}
		},
		"barChart":{
			"title":"Top 5 de lockers mas utilizados",
			"legends":{
				"use_of_locker":"Uso del locker"
			}
		},
		"barChartComparative":{
			"title":"Total de paquetes expirados",
			"categories":{
				"d1":"Lunes",
				"d2":"Martes",
				"d3":"Miercoles",
				"d4":"Jueves",
				"d5":"Viernes",
				"d6":"Sabado",
				"d7":"Domingo"
			},
			"legends":{
				"deliver_package":"Paquetes entregados",
				"undeliver_package":"Paquetes no entregados"
			}
		}
	},
	"ordersPage":{
		"title":"Ordenes",
		"cards":{
			"cardsOrders":{
				"card-1":{
					"title":"Uso total",
					"footer":"Informacion no disponible",
					"tooltip":"Conteo de todas las ordenes entregadas, recolectadas y devueltas desde el inicio del uso del sistema."
				},
				"card-2":{
					"title":"Uso medio por hora",
					"footer":"Informacion no disponible",
					"tooltip":"Promedio de uso total por horas activas del locker. Se calcula en base a las ordenes entregadas y recolectadas ordenes recolectadas por hora"
				},
				"card-3":{
					"title":"Horario de mayor uso",
					"footer":"Informacion no disponible",
					"tooltip":"Hora donde se entregan y recolectan mas ordenes."
				}
			}
		},
		"metrics":{
			"delivered":"Entregados",
			"collected":"Recolectado",
			"expired":"Expirado"
		},
		"tables":{
			"general":{
				"storeName":"Nombre de\nTienda",
				"storeAlias":"Alias de\nTienda",
				"storeCode":"Codigo de\nTienda",
				"address":"Direccion",
				"latitude":"Latitud",
				"longitude":"Longitud",
				"state":"Estado",
				"city":"Ciudad",
				"referenceCode":"CR",
				"openingHours":"Horario de atención",
				"openening_time":"Hora de\napertura",
				"closing_time":"Hora de\nclausura",
				"packageManaged":"Paquetes \nmanejados",
				"accion":"Accion"
			}
		},
		"filters":{
			"title":"Filtros",
			"status_title":"Estatus",
			"status":{
				"return":"Devueltos",
				"completed":"Completadas",
				"in_locker":"En locker",
				"expired":"Expirado",
				"canceled":"Canceladas",
				"cancelInLocker":"Cancelado en locker",
				"cancelWithdrawn":"Cancelado retirado"
			},
			"rate_title":"Calificacion"
		},
		"estatus":{
			"RECOLECTADO":"Recolectado",
			"RETURN": "Devuelto",
			"ENTREGADO":"Entregado",
			"SCHEDULED":"Agendado",
			"PENDIENTE": "Pendiente",
			"EXPIRED":"Expirado",
			"EXTENDED":"Extendido",
			"LOCKER":"En Locker",
			"CANCELED":"Cancelado",
			"CANCELED_LOCKER": "Cancelado En Locker",
			"CANCELED_PICKED": "Cancelado Retirado",
			"PASSWORD_EXPIRADO":"Password Expirado",
			"ACTIVE":"Activo",
			"DESACTIVE":"Desactivo",
			"PackageCollected":"Paquete Colectado",
			"PackageInLocker":"Paquete en Locker",
			"PackageExpired":"Paquete Expirado",
			"Warehouse":"En Almacen",
			"ScheduledDoor":"Puerta Programada",
			"OpenDoor": "Puerta Abierta",
			"CloseDoor": "Puerta Cerrada",
			"FailDoor": "Fallo en Puerta",
			"BusyDoor":"Puerta Ocupada",
			"loginSuccess":"Exito en Login",
			"loginError":"Intento log-in",
			"useMasterCode":"Uso Master Code",
			"logout":"Log-out"
		}
	},
	"dialogToUploadFile":{
		"title":"Importar clientes",
		"subtitle":"Revisa que los datos de los clientes que quieras ingresar sea correctos. Esto no es una acción definitiva, puedes editar los valores de las tiendas manualmente y de manera individual una vez las hayas dado de alta. ",
		"table":{
			"name":"Nombre completo",
			"lastname":"Apellido",
			"email":"Correo",
			"telephone":"Teléfono",
			"isAccepted":"Estatus"
		},
		"buttons":{
			"addUsers":"Agregar clientes",
			"cancel":"Cancelar"
		}
	},
	"menu" :{
        "orders":"Ordenes",
        "appearance":"Apariencia",
        "lang":"Idioma",
        "light":"Claro",
        "dark":"Oscuro",
        "integrations":"Integraciones",
        "version":"Versión del sistema",
        "profile":"Perfil",
        "account":"Mi cuenta",
		"routes" :"Rutas",
		"request":"Solicitudes",
		"inventory":"Inventario por cliente",
		"locker_tolocker":"Locker to locker",
		"add_orders":"Ingresar Ordenes",
		"fullfillment":"Fullfillment",
		"incidents":"Incidencias",
		"partners":"Socios PUDO"
    },
	"settings":{
		"profile":"Mi perfil",
		"integrations":"Integraciones",
		"webhook":"Configuracion Webhook",
		"version":"Versión del sistema",
		"logout":"Cerrar sesión"
	},
	"page403":{
		"title":"Error 403",
		"subtitle":"Acceso Denegado",
		"paragraph":"Parece que no tiene permiso para ver esta página. Por favor, intente acceder de nuevo, y si este error persiste, no dude en contactar con nosotros. Le ayudaremos a resolverlo."
	},
	"page404":{
		"title":"Error 404",
		"subtitle":"Página no disponible ",
		"paragraph":"Sentimos no haber podido encontrar lo que buscaba. Intente actualizar la página o volver a la página de inicio."
	},
	"page500":{
		"title":"Error 500",
		"subtitle":"Página no disponible ",
		"paragraph":"Parece que hemos encontrado un problema, pero no te preocupes. Intente actualizar la página o volver a la página de inicio."
	},
	"page503":{
		"title":"Error 503",
		"subtitle":"Este servicio no esta disponible",
		"paragraph":"El servicio al que intenta acceder no está disponible en este momento. Intente actualizar la página o volver a la página de inicio."
	},
	"navbar":{
        "overview":"Overview",
        "locker":"Locker",
        "orders":"Órdenes",
        "return":"Returns",
        "carrier":"Carriers",
        "users":"Usuarios",
        "clients":"Clientes",
        "welcome":"bienvenido de nuevo",
		"company":"Compañía",
        "profile":"Perfil",
		"stores":"Tiendas",
		"panel":"Panel de control",
		"account":"Cuenta",
		"oxxo_to_oxxo":"OXXO a OXXO",
		"active_routes":"Rutas Activas",
        "lang":"Idioma",
        "appearance":"Apariencia",
        "integrations":"Integraciones",
        "version":"Versión del sistema",
        "webhook":"Webhook Settings",
		"myaccount":"Mi cuenta",
        "es":"Español",
		"en":"Inglés",
        "dark":"Modo oscuro",
        "light":"Modo claro",
        "log-package":"Auditoría de paquetes"
    },
	"month":{
		"Ene":"Ene",
		"Feb":"Feb",
		"Mar":"Mar",
		"Abr":"Abr",
		"May":"May",
		"Jun":"Jun",
		"Jul":"Jul",
		"Ago":"Ago",
		"Sep":"Sep",
		"Oct":"Oct",
		"Nov":"Nov",
		"Dic":"Dic"
	},
	"uploadDocument": {
		"buttonLabel": "Subir clientes",
		"popupTitle": "Importa tus datos de clientes",
		"popupSubtitle": "Puedes subir un archivo con los datos de tus clientes. Si aún no tienes un archivo con el formato correcto, puedes descargar una plantilla",
		"popupLink":"dando clic aquí.",
		"fileUploader": {
			"link":"Da clic aqui",
			"clickHere": " para abrir tu explorador de archivos y seleccionar el archivo a importar.",
			"fileSizeLimit": "El archivo que subas no puede ser mayor a 10MB."
		},
		"fileUpload": {
			"fileName": "{{this.file.name}}",
			"fileSize": "{{ (this.file.size / 1024 / 1024).toFixed(2) }} MB",
			"progress": "{{this.progress}}%"
		},
		"errors": {
			"fileSizeOver": "Parece que el archivo que intentas subir pesa más de 10MB. Revísalo e inténtalo de nuevo.",
			"invalidFileType": "El tipo de archivo no es válido. Sólo se permiten archivos Excel(extension csv o xls)..",
			"invalidHeaders": "El archivo no contiene los encabezados requeridos: Nombre, Apellido, Email, Teléfono."
		},
		"alert": {
			"text": "Usuarios creados",
			"subtitle": "Los usuarios han sido creados"
		}
	},
	"notifications":{
		"title":"Notifications",
		"checkItAll":"Mark all as read",
		"button": "View activity",
		"switchContent":{
			"orders" 		: "Orders",
			"users" 		: "Users",
			"all" 			: "All"
		},
		"empty":{
			"title": "There's nothing here",
			"subtitle":"Here you'll find your notifications for packages or operational issues."
		},
		"items":{
			"packet":"The package",
			"user":"The user",
			"statusTopic":{
				"4":"has logged into",
				"5":"has logged out of",
				"6":"has encountered an error in",
				"7":"has attempted to log in to",
				"8":"has picked up a package at",
				"9":"has delivered a package at",
				"10":"has used the master code at",
				"11":"has picked up an expired package at",
				"14":"has returned a package to the",
				"25":"has been entered into",
				"26":"has been assigned to",
				"27":"is in transit from origin CEDIS",
				"28":"is located at origin CEDIS",
				"29":"is in transit to destination CEDIS",
				"30":"is at destination CEDIS",
				"31":"is in transit to",
				"32":"is being inspected at CEDIS",
				"33":"has been inspected",
				"34":"has been confiscated",
				"35":"is in ",
				"36":"has been delivered",
				"37":"has expired at",
				"38":"has been picked up from",
				"39":"has been picked up by the customer",
				"40":"is in the process of being returned to origin",
				"41":"has been returned to the originating customer",
				"42":"has entered legal proceedings"
			},
			"ago":"Ago",
			"minutes":"minutes",
			"hours":"hours",
			"days":"days"
		}
	},
	"systemVersionPage":{
		"title":"Versión del sistema",
		"table":{
			"version":"versión",
			"date":"fecha",
            "json":"Ver Json"
		}
	},
	"orderDetail":{
        "title-orderDetail":"Detalles de la orden",
        "subtitle-informationGeneral":"Información General",
        "informationGeneral":{
            "trackingNumber":"Número de Tracking",
			"otpValidation":"OTP",
			"orderNumber":"Numero de orden",
            "emailClient":"Email del Cliente",
            "phoneClient":"Teléfono del Cliente",
            "nameEmployee":"Nombre del Empleado",
            "numberOfDoor":"Número de Puerta",
            "orderStatus":"Estatus de la Orden",
            "typeDelivery":"Tipo de Entrega",
            "inLocker":"Recibido en el Locker",
			"scheduledDate":"Fecha agendado",
            "expirationDate":"Fecha de Expiración",
			"cancelDate":"Fecha de Cancelacion",
            "removedLocker":"Retirado del Locker",
            "deliverDate":"Fecha de Entrega",
            "qualificationClient":"Calificacion del Cliente"
        },
        "subtitle-accessQR":"QR de Acceso",
        "qrAccess":{
			"btnDownload":"Descargar"
        },
        "subtitleReminder":"Recordatorios",
        "Reminder":{
			"addReminder":"Agregar recordatorio",
			"noReminder":"Los recordatorios que agregues se verán aqui."
        },
        "subtitleEvidence":"Evidencia de entrega",
        "Evidence":{
            "signEvidence":"Firma",
            "recollectionEvidence":"Foto de recolección"
        },
        "eraseOrder":"Borrar Orden"
    },

	"estatus":{
        "RECOLECTADO":"Recolectado",
		"RETURN": "Devuelto",
        "ENTREGADO":"Entregado",
        "SCHEDULED":"Agendado",
		"PENDIENTE": "Pendiente",
		"EXPIRED":"Expirado",
		"EXTENDED":"Extendido",
        "LOCKER":"En Locker",
		"CANCELED":"Cancelado",
		"CANCELED_LOCKER": "Cancelado En Locker",
		"CANCELED_PICKED": "Cancelado Retirado",
		"PASSWORD_EXPIRADO":"Password Expirado",
        "ACTIVE":"Activo",
        "DESACTIVE":"Desactivo",
        "PackageCollected":"Paquete Colectado",
        "PackageInLocker":"Paquete en Locker",
        "PackageExpired":"Paquete Expirado",
        "Warehouse":"En Almacen",
        "ScheduledDoor":"Puerta Programada",
        "OpenDoor": "Puerta Abierta",
        "CloseDoor": "Puerta Cerrada",
        "FailDoor": "Fallo en Puerta",
        "BusyDoor":"Puerta Ocupada",
		"loginSuccess":"Exito en Login",
		"loginError":"Intento log-in",
		"useMasterCode":"Uso Master Code",
		"logout":"Log-out"
    },
	"companyPage":{
		"title":"Compañia",
		"cards":{
			"card-1":{
				"title":"Kpi pendiente",
				"footer":"Informacion no disponible",
				"tooltip":"Tooltip de KPI Pendiente."
			},
			"card-2":{
				"title":"Kpi pendiente",
				"footer":"Informacion no disponible",
				"tooltip":"Tooltip de KPI Pendiente."
			},
			"card-3":{
				"title":"Kpi pendiente",
				"footer":"Informacion no disponible",
				"tooltip":"Tooltip de KPI Pendiente."
			},
			"card-4":{
				"title":"Kpi pendiente",
				"footer":"Informacion no disponible",
				"tooltip":"Tooltip de KPI Pendiente."
			}
		},
		"buttons":{
			"add_company":"Agregar compañia"
		},
		"forms":{
			"company":{
				"company_name":"Nombre de compañia",
				"email":"Email",
				"password":"Contraseña",
				"trfc":"RFC",
				"address":"Direccion",
				"phone_number":"Numero telefonico",
				"acronym":"Acronimo",
				"copyright":"Copyright",
				"latitud":"Latitud",
				"longitud":"Longitud",
				"city":"Ciudad"
			},
			"branchs":{
				"name_propierty":"Nombre de propiedad",
				"latitud":"Latitud",
				"longitud":"Longitud",
				"address":"Direccion",
				"description":"Descripcion"
			}
		},
		"table":{
			"nombre":"Nombre",
			"rfc":"RFC",
			"email":"Email",
			"number_branches":"Cantidad de sucursales",
			"accion":"Accion"
		},
		"branchTable":{
			"name_propierty":"Nombre de propiedad",
			"address":"Direccion",
			"description":"Descripcion",
			"latitud":"Latitud",
			"longitud":"Longitud"
		}
	}

}
