{
	"side-bar-panel-control": {
		"account": "My Account Information",
		"profile": "My Profile",
		"password": "Password",
		"pref": "Preferences",
		"config": "Webhook Config",
		"account-page": {
			"name": "Full Name",
			"email": "Contact Email",
			"header-2": "Your Company Information",
			"company": "Company",
			"type": "User Type",
			"ID": "Employee ID",
			"header-3": "Master Code",
			"code": "Your Code for Today",
			"btn-generate": "Generate New Code",
			"btn": "Save Changes"
		},
		"password-page": {
			"txt-1": "If you want to change your password, enter the current one to start the process.",
			"current": "Current Password",
			"new": "New Password",
			"confirm": "Confirm Password"
		},
		"preferences-page": {
			"dark-mode": "Dark Mode",
			"light-mode": "Light Mode",
			"language": "Language",
			"es": "Spanish",
			"en": "English"
		}
	},
	"buttons": {
		"resetChanges": "Reset Changes",
		"saveInformation": "Save Information",
		"saveChanges": "Save Information"
	},
	"accountSettingsPage": {
		"title": "My Account",
		"switchContent": {
			"perfil": "Profile",
			"notifycations": "Notifications",
			"preferences": "Preferences"
		},
		"perfil": {
			"my-information": {
				"title": "My Information",
				"subtitle": "Thanks to this information, we can contact you if necessary, as well as for returns or clarifications.",
				"username": "Username",
				"placeholderUsername": "Account Holder's Name",
				"idEmployee": "Employee ID",
				"placeholderIDEmployee": "1324-5676",
				"email": "Email",
				"placeholder": "name@example.com",
				"telephone": "Contact Phone",
				"placeholderTelephone": "(81)1625-6549"
			},
			"password": {
				"title": "Password",
				"subtitle": "Enter your current password to create a new one.",
				"form": {
					"actual-password": "Current Password",
					"new-password": "Your New Password",
					"boxValidation": {
						"title": "To protect your account, make sure your password has:",
						"validation1": "An uppercase letter",
						"validation2": "A number",
						"validation3": "A special character",
						"validation4": "At least 8 characters"
					},
					"confirmPassword": "Confirm Your Password",
					"btnUpdatePassword": "Update My Password"
				}
			}
		},
		"notifications": {
			"dashboard-notifications": {
				"title": "Dashboard Notifications",
				"subtitle": "Select the types of notifications you want to receive. System notifications cannot be disabled.",
				"selects": {
					"title-order": "Orders",
					"subtitle-order": "Received orders, in delivery route, in locker, collected, expired, etc.",
					"title-fulfillment": "Fulfillment",
					"subtitle-fulfillment": "Products received in warehouse, restocks, out-of-stock, and zero-stock units.",
					"title-tickets": "Payment Tickets",
					"subtitle-tickets": "New tickets, due dates, approved or rejected payments."
				}
			},
			"dashboard-email": {
				"title": "Email Notifications",
				"subtitle": "These notifications are sent whenever something important happens with your account. Select the types of emails you want to receive.",
				"selects": {
					"title-order": "Orders",
					"subtitle-order": "Received orders, in delivery route, in locker, collected, expired, etc.",
					"title-fulfillment": "Fulfillment",
					"subtitle-fulfillment": "Products received in warehouse, restocks, out-of-stock, and zero-stock units.",
					"title-tickets": "Payment Tickets",
					"subtitle-tickets": "New tickets, due dates, approved or rejected payments.",
					"title-surveys": "Surveys",
					"subtitle-surveys": "These surveys help us improve and provide you with a better experience."
				}
			}
		},
		"preferences": {
			"appearance": {
				"title": "Appearance",
				"subtitle": "Customize the interface to your liking by selecting the primary color or the mode.",
				"color-primary": {
					"title": "Primary Color",
					"subtitle": "This color will be applied to buttons and borders."
				},
				"mode": {
					"title": "Mode",
					"light": "Light",
					"dark": "Dark"
				}
			},
			"language": {
				"title": "Language",
				"subtitle": "Customize your application's appearance, change its primary color or mode, either light or dark.",
				"lang": {
					"es": "Spanish",
					"en": "English"
				}
			}
		}
	},
	"loginPage": {
		"title": "Welcome",
		"subtitle": "Please enter the following information to continue.",
		"form": {
			"email": "Email",
			"password": "Password",
			"checkbox": "Remember my information",
			"forgetPassword": "Forgot my password",
			"btnLogin": "Log In",
			"footer": "New to fulfillment?",
			"footerLink": "We can help you"
		}
	},
	"createNewPassword": {
		"forgotPasswordForm": {
			"title": "I Forgot My Password",
			"subtitle": "Don't worry, it can happen to anyone. Enter the email associated with your account, and we'll tell you how to recover it.",
			"email": "Email",
			"recoverPassword": "Recover My Password",
			"buttonThrowback": "Return to Main Screen"
		},
		"title": "Create a New Password",
		"subtitle": "Complete the following fields to recover your account and continue sending your products.",
		"form": {
			"labelNewPassword": "Your New Password",
			"boxValidation": {
				"title": "To protect your account, make sure your password has:",
				"validation1": "An uppercase letter",
				"validation2": "A number",
				"validation3": "A special character",
				"validation4": "At least 8 characters"
			},
			"confirmPassword": "Confirm Your Password",
			"btnUpdatePassword": "Update My Password"
		},
		"passwordChanged": {
			"title": "All Set!",
			"subtitle": "We knew you'd be back. You've recovered your account and can now continue managing your logistics network with us."
		},
		"passwordNotChanged": {
			"title": "Oops, There Was an Error!",
			"subtitle": "We're sorry for the inconvenience. Please try the password reset process again."
		},
		"oneStepMore": {
			"title": "Just One More Step...",
			"subtitle-1": "If the email",
			"subtitle-2": "is associated with an account, you will receive an email with the next steps. Be sure to check your junk or spam folder.",
			"paragraph-1": "Not sure about the email you entered?",
			"paragraph-2": "Try a different one."
		},
		"buttons": {
			"throwback-login": "Go to Main Screen",
			"updatePassword": "Update My Password",
			"sendEmail": "Resend Email",
			"returnBackLogin": "Return to Main Screen"
		}
	},
	"sidebarComponent": {
		"settings": {
			"title": "Settings",
			"languages": {
				"title": "Language",
				"es": "Spanish",
				"en": "English"
			},
			"appearance": {
				"title": "Appearance",
				"light": "Light",
				"dark": "Dark"
			}
		}
	},
	"lockerPage": {
		"title": "Logistics Network, Lockers",
		"cards": {
			"cardsOrders": {
				"card-1": {
					"title": "Delivered Orders",
					"tooltip": "Total number of deliveries since the system started being used.",
					"footer": "Information not available"
				},
				"card-2": {
					"title": "Collected Orders",
					"tooltip": "Total number of collections since the system started being used.",
					"footer": "vs last month"
				},
				"card-3": {
					"title": "Orders in Locker",
					"tooltip": "Total number of packages currently in all our lockers.",
					"footer": "vs last month"
				}
			},
			"mapCard": {
				"title": "Locations"
			}
		},
		"location": "Location",
		"input-search": "Search...",
		"filters": "Filters",
		"export": "Export",
		"table": {
			"alias": "Locker Alias",
			"location": "Location",
			"totalDeliveries": "Total Deliveries",
			"doors": "Doors",
			"doorsAvailable": "Available Doors",
			"status": "Status"
		},
		"statusLocker": {
			"1": "Active",
			"2": "Inactive"
		}
	},
	"utcPage": {
		"title": "Logistics Network, UTC",
		"cards": {
			"bigCard": {
				"title": "Total Stores"
			},
			"cardsUTC": {
				"card-1": {
					"title": "Active UTCs",
					"tooltip": "Active UTC tooltip",
					"footer": "Information not available"
				},
				"card-2": {
					"title": "Inactive UTCs",
					"tooltip": "Inactive UTC tooltip",
					"footer": "Information not available"
				}
			},
			"cardsOrders": {
				"card-1": {
					"title": "Completed Orders",
					"tooltip": "Completed Orders tooltip",
					"footer": "Information not available"
				},
				"card-2": {
					"title": "Orders in Process",
					"tooltip": "Orders in Process tooltip",
					"footer": "vs last month"
				},
				"card-3": {
					"title": "Expired Orders",
					"tooltip": "Expired Orders tooltip",
					"footer": "vs last month"
				},
				"card-4": {
					"title": "Returned Orders",
					"tooltip": "Returned Orders tooltip",
					"footer": "vs last month"
				}
			},
			"mapCard": {
				"title": "Locations"
			}
		},
		"table": {
			"alias": "UTC Alias",
			"store": "Store",
			"referenceCode": "CR",
			"openingHours": "Opening Hours",
			"spacesAvailable": "Available Spaces",
			"systemStatus": "System Status"
		},
		"components": {
			"status": {
				"active": "Active",
				"inactive": "Inactive"
			}
		},
		"overlayNoContent": {
			"title": "No Results",
			"subtitle": "It seems we couldn't find any items that match your search."
		},
		"overlayLoading": {
			"title": "Loading"
		}
	},
	"utcDetailPage": {
		"breadcrumb": {},
		"contentSwitcher": {
			"resumen": "Summary",
			"actividad": "Activity"
		},
		"cards": {
			"cardsUTC": {
				"card-1": {
					"title": "Avg. Usage vs Capacity",
					"tooltip": "Avg. Usage vs Capacity tooltip",
					"footer": "vs last month"
				},
				"card-2": {
					"title": "OXXO to OXXO, Local",
					"tooltip": "OXXO to OXXO, Local tooltip",
					"footer": "vs last month"
				},
				"card-3": {
					"title": "OXXO to OXXO, National",
					"tooltip": "OXXO to OXXO, National tooltip",
					"footer": "vs last month"
				},
				"card-4": {
					"title": "PUDO Partners",
					"tooltip": "PUDO Partners tooltip",
					"footer": "vs last month"
				}
			},
			"cardsOrders": {
				"card-1": {
					"title": "Completed Orders",
					"tooltip": "Completed Orders tooltip",
					"footer": "vs last month"
				},
				"card-2": {
					"title": "Expired Orders",
					"tooltip": "Expired Orders tooltip",
					"footer": "vs last month"
				},
				"card-3": {
					"title": "Returned Orders",
					"tooltip": "Returned Orders tooltip",
					"footer": "vs last month"
				},
				"card-4": {
					"title": "Total Traffic per Unit",
					"tooltip": "Total Traffic per Unit tooltip",
					"footer": "vs last month"
				}
			},
			"cardMap": {
				"title": "Location"
			}
		},
		"table": {
			"contentSwitcher": {
				"todas": "All Orders",
				"proceso": "In Process",
				"expiradas": "Expired",
				"agendadas": "Scheduled",
				"completadas": "Completed",
				"canceladas": "Cancelled"
			},
			"activity": {
				"orderNumber": "Order Number",
				"event": "Event",
				"dateEvent": "Event Date",
				"description": "Description"
			},
			"general": {
				"orderNumber": "Order Number",
				"model": "Model",
				"sender": "Sender",
				"addressee": "Addressee",
				"origin": "Origin",
				"destination": "Destination",
				"status": "Status",
				"last_update": "Last Update"
			},
			"orderNumber": "Order Number",
			"sender": "Sender",
			"addressee": "Addressee",
			"origin": "Origin",
			"destination": "Destination",
			"status": "Status",
			"last_update": "Last Update"
		},
		"comparments": {
			"disponible": "Available",
			"agendado": "Scheduled",
			"expirado": "Expired",
			"abierto": "Open",
			"ocupado": "Occupied",
			"defectuoso": "Defective"
		}
	},
	"lockerDetailPage": {
		"breadcrumb": {},
		"contentSwitcher": {
			"resumen": "Summary",
			"actividad": "Activity"
		},
		"cards": {
			"cardsLocker": {
				"card-1": {
					"title": "Small Doors",
					"tooltip": "Dimensions: 8cm height, 37.5cm width, 55cm depth",
					"footer": "Available"
				},
				"card-2": {
					"title": "Medium Doors",
					"tooltip": "Dimensions: 18cm height, 37.5cm width, 55cm depth",
					"footer": "Available"
				},
				"card-3": {
					"title": "Large Doors",
					"tooltip": "Dimensions: 28cm height, 37.5cm width, 55cm depth",
					"footer": "Available"
				},
				"card-4": {
					"title": "Extra Large Doors",
					"tooltip": "Dimensions: 47.5cm height, 37.5cm width, 55cm depth",
					"footer": "Available"
				}
			},
			"cardsOrders": {
				"card-1": {
					"title": "Total Deliveries (all time)",
					"tooltip": "Total Deliveries tooltip (all time)",
					"footer": "vs last month"
				},
				"card-2": {
					"title": "Total Returns (all time)",
					"tooltip": "Total Returns tooltip (all time)",
					"footer": "vs last month"
				},
				"card-3": {
					"title": "Total Deliveries (current day)",
					"tooltip": "Total Deliveries tooltip (current day)",
					"footer": "vs last month"
				},
				"card-4": {
					"title": "Total Returns (current day)",
					"tooltip": "Total Returns tooltip (current day)",
					"footer": "vs last month"
				}
			},
			"cardMap": {
				"title": "Location"
			}
		},
		"table": {
			"contentSwitcher": {
				"todas": "All Orders",
				"proceso": "In Process",
				"expiradas": "Expired",
				"agendadas": "Scheduled",
				"completadas": "Completed",
				"canceladas": "Cancelled"
			},
			"activity": {
				"eventId": "Event ID",
				"event": "Event",
				"dateEvent": "Event Date",
				"description": "Description"
			},
			"general": {
				"tracking_number": "Order Number",
				"locker_name": "Locker",
				"email": "Email",
				"door_number": "Door",
				"status": "Status",
				"date_in_locker": "Date Received",
				"date_collected": "Date Collected"
			},
			"expired": {
				"tracking_number": "Tracking Number",
				"email": "Email",
				"door_number": "Door",
				"status": "Status",
				"date_in_locker": "Date Received",
				"date_expired": "Expiration Date"
			},
			"completed": {
				"tracking_number": "Tracking Number",
				"locker_name": "Locker",
				"email": "Email",
				"door_number": "Door",
				"status": "Status",
				"date_in_locker": "Date Received",
				"date_collected": "Date Collected"
			},
			"schedule": {
				"package": "Package",
				"otp": "OTP",
				"email": "Email",
				"door_number": "Door",
				"size_compartment": "Size",
				"status": "Status",
				"register_date": "Registration Date"
			},
			"canceled": {
				"tracking_number": "Tracking Number",
				"nameEmploye": "Employee Who Canceled",
				"door_number": "Door",
				"status": "Status",
				"date_collected": "Date Collected",
				"date_cancel": "Cancellation Date",
				"locker_name": "Locker"
			}
		},
		"estatus": {
			"RECOLECTADO": "Collected",
			"RETURN": "Returned",
			"ENTREGADO": "Delivered",
			"SCHEDULED": "Scheduled",
			"PENDIENTE": "Pending",
			"EXPIRED": "Expired",
			"EXTENDED": "Extended",
			"LOCKER": "In Locker",
			"CANCELED": "Canceled",
			"CANCELED_LOCKER": "Canceled In Locker",
			"CANCELED_PICKED": "Canceled Picked Up",
			"PASSWORD_EXPIRADO": "Password Expired",
			"ACTIVE": "Active",
			"DESACTIVE": "Inactive",
			"PackageCollected": "Package Collected",
			"PackageInLocker": "Package in Locker",
			"PackageExpired": "Package Expired",
			"Warehouse": "In Warehouse",
			"ScheduledDoor": "Scheduled Door",
			"OpenDoor": "Open Door",
			"CloseDoor": "Closed Door",
			"FailDoor": "Door Failure",
			"BusyDoor": "Busy Door",
			"loginSuccess": "Login Success",
			"loginError": "Login Attempt",
			"useMasterCode": "Use Master Code",
			"logout": "Logout"
		},
		"comparments": {
			"disponible": "Available",
			"agendado": "Scheduled",
			"expirado": "Expired",
			"abierto": "Open",
			"ocupado": "Occupied",
			"defectuoso": "Defective"
		}
	},
	"usersPage": {
		"title":"Users",
		"table": {
			"client_name": "Full Name",
			"email": "User Email",
			"active_days": "Active Days",
			"register_date": "Registration Date",
			"expired_date": "Credential Expiration Date",
			"status": "User Status",
			"actions": "Action"
		},
		"buttons":{
			"createUser":"Create user"
		},
		"overlays": {
			"noContent": {
				"title": "Nothing here...",
				"subtitle": "In this table, you will find all users and information about them."
			}
		},
		"dialogs": {
			"dialogChangeStatusUser": {
				"headerTitle": "Are you sure you want to deactivate this user?",
				"subHeaderTitle": "Deactivating a user will revoke their access to the dashboard, and they won't be able to deliver packages to any locker. You can reactivate this user at any time.",
				"buttons": {
					"cancel": "Cancel",
					"desactivate": "Yes, I want to deactivate it"
				}
			},
			"dialogEditUser": {
				"title": "Edit User",
				"editUser": {
					"title": "Add User",
					"fieldNameCompany": "Company Name",
					"fieldIdEmployee": "Employee ID",
					"fieldTypeEmployee": "User Type",
					"fieldNameEmployee": "Full Name",
					"fieldEmail": "Email",
					"fieldPassword": "Password",
					"fieldConfirmPassword": "Confirm Password",
					"btnGeneratePassword": "Generate Password",
					"btnCancel": "Cancel",
					"btnContinue": "Continue",
					"errorFieldRequired": "Required field",
					"errorFieldPassword": "Passwords do not match",
					"errorFieldEmail": "Please ensure a valid email is entered",
					"errorFieldId": "Required field. Must contain between 6 and 8 characters.",
					"passwordInfo": "The password for access will be sent to the email you provided.",
					"passwordInfoCarrier": "The password for access will be sent to the email: "
				}
			},
			"dialogAddUser": {
				"title": "Add User",
				"fields": {
					"typeEmployee": "User Type",
					"email": "Email",
					"nameEmployee": "Full Name",
					"passwordInfo": "The password for access will be sent to the email you provided."
				},
				"buttons": {
					"cancel": "Cancel",
					"saveUser": "Save User"
				}
			}
		},
		"cards": {
			"card-1": {
				"title": "User KPI",
				"tooltip": "User KPI Tooltip",
				"footer": "Information unavailable"
			},
			"card-2": {
				"title": "User KPI",
				"tooltip": "User KPI Tooltip",
				"footer": "Information unavailable"
			},
			"card-3": {
				"title": "User KPI",
				"tooltip": "User KPI Tooltip",
				"footer": "Information unavailable"
			},
			"card-4": {
				"title": "User KPI",
				"tooltip": "User KPI Tooltip",
				"footer": "Information unavailable"
			},
			"card-5": {
				"title": "User KPI",
				"tooltip": "User KPI Tooltip",
				"footer": "Information unavailable"
			},
			"card-6": {
				"title": "User KPI",
				"tooltip": "User KPI Tooltip",
				"footer": "Information unavailable"
			}
		}
	},
	"clientsPage": {
		"title": "Clients",
		"cards": {
			"card-1": {
				"title": "Total Clients",
				"tooltip": "Total number of users since the system's usage started.",
				"footer": "Information unavailable"
			},
			"card-2": {
				"title": "Unique Clients",
				"tooltip": "Clients who pick up orders only once.",
				"footer": "Information unavailable"
			},
			"card-3": {
				"title": "Repeat Clients",
				"tooltip": "Clients who pick up orders more than twice.",
				"footer": "Information unavailable"
			}
		},
		"metric": {
			"title": "Total Shipping Volume",
			"store": "Store",
			"locker": "Locker",
			"days": {
				"monday": "Monday",
				"tuesday": "Tuesday",
				"wednesday": "Wednesday",
				"thursday": "Thursday",
				"friday": "Friday",
				"saturday": "Saturday",
				"sunday": "Sunday"
			}
		},
		"table": {
			"client_name": "Client Name",
			"email": "Email",
			"viviID": "VIVI ID",
			"paid_packages": "Paid Packages",
			"register_date": "Registration Date"
		}
	},
	"timePicker": {
		"hour": "Hour",
		"minute": "Minute",
		"buttons": {
			"erase": "Erase",
			"cancel": "Cancel",
			"accept": "Accept"
		}
	},
	"storesPage": {
		"title": "Logistics Network, Stores",
		"tables": {
			"createStore": "Create Store",
			"createStores": "Create Stores",
			"general": {
				"storeName": "Store Name",
				"storeAlias": "Store Alias",
				"storeCode": "Store Code",
				"address": "Address",
				"latitude": "Latitude",
				"longitude": "Longitude",
				"state": "State",
				"city": "City",
				"referenceCode": "CR",
				"openingHours": "Opening Hours",
				"openening_time": "Opening Time",
				"closing_time": "Closing Time",
				"packageManaged": "Packages Managed",
				"accion": "Action"
			}
		},
		"dialog": {
			"dialogCreateStore": {
				"title": "Add Store",
				"info": "Enter the following details to register a store. All fields are required.",
				"location": "Choose the store location",
				"fields": {
					"storeName": "Store Name",
					"storeAlias": "Store Alias",
					"state": "State",
					"city": "Plaza",
					"address": "Address",
					"latitud": "Latitude",
					"longitud": "Longitude",
					"codeReference": "CR",
					"capacity": "Service Capacity",
					"openingHours": "Opening Hours",
					"storeOpening": "Opening Time",
					"storeClosing": "Closing Time"
				},
				"buttons": {
					"cancel": "Cancel",
					"accept": "Accept"
				}
			},
			"dialogImportStore": {
				"fields": {
					"store": "Store",
					"storeAlias": "Store Alias",
					"codeReference": "CR",
					"state": "State",
					"plaza": "Plaza",
					"openingHours": "Opening Hours"
				}
			},
			"dialogEditStore": {
				"title": "Edit Store",
				"location": "Choose the store location",
				"fields": {
					"storeName": "Store Name",
					"storeAlias": "Store Alias",
					"state": "State",
					"city": "City",
					"address": "Address",
					"latitud": "Latitude",
					"longitud": "Longitude",
					"codeReference": "CR",
					"storeOpening": "Opening Time",
					"storeClosing": "Closing Time"
				},
				"buttons": {
					"cancel": "Cancel",
					"accept": "Accept"
				}
			},
			"dialogDeleteStore": {
				"title": "Are you sure you want to delete this store?",
				"delete": "When deleting a store, please note that:\nScheduled packages will not be delivered.",
				"buttons": {
					"cancel": "Cancel",
					"accept": "Accept"
				}
			}
		},
		"buttons": {
			"createStore": "Add Store",
			"importStore": "Import Stores"
		}
	},
	"storeDetailPage": {
		"title": "Stores",
		"cards": {
			"openingTime": {
				"title": "Opening Hours"
			},
			"closingTime": {
				"title": "Closing Hours"
			},
			"location": {
				"title": "Location"
			}
		},
		"tables": {
			"general": {
				"tracking_number": "Tracking",
				"registration_date": "Registration Date",
				"entry_date": "Entry Date",
				"expiration_date": "Expiration Date",
				"package_status_id": "Status ID",
				"package_status": "Status",
				"volume": "Volume",
				"type": "Service Type",
				"company": "Company",
				"destination_store": "Destination Store",
				"id_courier": "Courier ID",
				"courier_name": "Courier"
			},
			"couriers": {
				"courier_name": "Courier",
				"signature_required": "Signatures Required",
				"id_status": "Status ID"
			}
		}
	},
	"overviewPage": {
		"title": "Welcome, ",
		"export": "Export",
		"heatMap": {
			"title": "Peak Usage Time",
			"categories": {
				"d1": "Monday",
				"d2": "Tuesday",
				"d3": "Wednesday",
				"d4": "Thursday",
				"d5": "Friday",
				"d6": "Saturday",
				"d7": "Sunday"
			}
		},
		"barChart": {
			"title": "Top 5 Most Used Lockers",
			"legends": {
				"use_of_locker": "Locker Usage"
			}
		},
		"barChartComparative": {
			"title": "Total Expired Packages",
			"categories": {
				"d1": "Monday",
				"d2": "Tuesday",
				"d3": "Wednesday",
				"d4": "Thursday",
				"d5": "Friday",
				"d6": "Saturday",
				"d7": "Sunday"
			},
			"legends": {
				"deliver_package": "Delivered Packages",
				"undeliver_package": "Undelivered Packages"
			}
		}
	},
	"orderDetail": {
		"title-orderDetail": "Order Details",
		"subtitle-informationGeneral": "General Information",
		"informationGeneral": {
			"trackingNumber": "Tracking Number",
			"otpValidation": "OTP",
			"orderNumber": "Order Number",
			"emailClient": "Client Email",
			"phoneClient": "Client Phone",
			"nameEmployee": "Employee Name",
			"numberOfDoor": "Door Number",
			"orderStatus": "Order Status",
			"typeDelivery": "Delivery Type",
			"inLocker": "Received in Locker",
			"scheduledDate": "Scheduled Date",
			"expirationDate": "Expiration Date",
			"cancelDate": "Cancellation Date",
			"removedLocker": "Removed from Locker",
			"deliverDate": "Delivery Date",
			"qualificationClient": "Client Rating"
		},
		"subtitle-accessQR": "Access QR",
		"qrAccess": {
			"btnDownload": "Download"
		},
		"subtitleReminder": "Reminders",
		"Reminder": {
			"addReminder": "Add Reminder",
			"noReminder": "The reminders you add will appear here."
		},
		"subtitleEvidence": "Delivery Evidence",
		"Evidence": {
			"signEvidence": "Signature",
			"recollectionEvidence": "Collection Photo"
		},
		"eraseOrder": "Delete Order"
	},
	"ordersPage": {
		"title": "Orders",
		"cards": {
			"cardsOrders": {
				"card-1": {
					"title": "Total Usage",
					"footer": "Information unavailable",
					"tooltip": "Count of all orders delivered, collected, and returned since the system started."
				},
				"card-2": {
					"title": "Average Usage per Hour",
					"footer": "Information unavailable",
					"tooltip": "Average total usage per active hour of the locker. Calculated based on orders delivered and collected per hour."
				},
				"card-3": {
					"title": "Peak Usage Time",
					"footer": "Information unavailable",
					"tooltip": "Time when the most orders are delivered and collected."
				}
			}
		},
		"metrics":{
			"delivered":"Delivered",
			"collected":"Collected",
			"expired":"Expired"
		},
		"tables": {
			"general": {
				"storeName": "Store Name",
				"storeAlias": "Store Alias",
				"storeCode": "Store Code",
				"address": "Address",
				"latitude": "Latitude",
				"longitude": "Longitude",
				"state": "State",
				"city": "City",
				"referenceCode": "CR",
				"openingHours": "Opening Hours",
				"openening_time": "Opening Time",
				"closing_time": "Closing Time",
				"packageManaged": "Packages Managed",
				"accion": "Action"
			}
		},
		"filters": {
			"title": "Filters",
			"status_title": "Status",
			"status": {
				"return": "Returned",
				"completed": "Completed",
				"in_locker": "In Locker",
				"expired": "Expired",
				"canceled": "Canceled",
				"cancelInLocker": "Canceled in Locker",
				"cancelWithdrawn": "Canceled Withdrawn"
			},
			"rate_title": "Rating"
		},
		"estatus": {
			"RECOLECTADO": "Collected",
			"RETURN": "Returned",
			"ENTREGADO": "Delivered",
			"SCHEDULED": "Scheduled",
			"PENDIENTE": "Pending",
			"EXPIRED": "Expired",
			"EXTENDED": "Extended",
			"LOCKER": "In Locker",
			"CANCELED": "Canceled",
			"CANCELED_LOCKER": "Canceled In Locker",
			"CANCELED_PICKED": "Canceled Withdrawn",
			"PASSWORD_EXPIRADO": "Password Expired",
			"ACTIVE": "Active",
			"DESACTIVE": "Deactivated",
			"PackageCollected": "Package Collected",
			"PackageInLocker": "Package in Locker",
			"PackageExpired": "Package Expired",
			"Warehouse": "In Warehouse",
			"ScheduledDoor": "Scheduled Door",
			"OpenDoor": "Open Door",
			"CloseDoor": "Closed Door",
			"FailDoor": "Door Failure",
			"BusyDoor": "Door Occupied",
			"loginSuccess": "Login Success",
			"loginError": "Login Attempt",
			"useMasterCode": "Master Code Use",
			"logout": "Log-out"
		}
	},
	"dialogToUploadFile": {
		"title": "Import Customers",
		"subtitle": "Make sure the customer data you want to enter is correct. This is not a final action; you can manually edit the store values individually once they have been added.",
		"table": {
			"name": "Full Name",
			"lastname": "Last Name",
			"email": "Email",
			"telephone": "Phone",
			"isAccepted": "Status"
		},
		"buttons": {
			"addUsers": "Add Customers",
			"cancel": "Cancel"
		}
	},
	"menu": {
		"orders": "Orders",
		"appearance": "Appearance",
		"lang": "Language",
		"light": "Light",
		"dark": "Dark",
		"integrations": "Integrations",
		"version": "System Version",
		"profile": "Profile",
		"account": "My Account",
		"routes": "Routes",
		"request": "Requests",
		"inventory": "Inventory by Client",
		"locker_tolocker": "Locker to Locker",
		"add_orders": "Enter Orders",
		"fullfillment": "Fulfillment",
		"incidents": "Incidents",
		"partners": "PUDO Partners"
	},
	"settings": {
		"profile": "My Profile",
		"integrations": "Integrations",
		"webhook": "Webhook Settings",
		"version": "System Version",
		"logout": "Log out"
	},
	"page403": {
		"title": "Error 403",
		"subtitle": "Access Denied",
		"paragraph": "It seems you don't have permission to view this page. Please try again, and if the issue persists, feel free to contact us. We'll help you resolve it."
	},
	"page404": {
		"title": "Error 404",
		"subtitle": "Page Not Available",
		"paragraph": "We're sorry we couldn't find what you were looking for. Try refreshing the page or returning to the homepage."
	},
	"page500": {
		"title": "Error 500",
		"subtitle": "Page Not Available",
		"paragraph": "It seems we've encountered a problem, but don't worry. Try refreshing the page or returning to the homepage."
	},
	"page503": {
		"title": "Error 503",
		"subtitle": "This Service is Unavailable",
		"paragraph": "The service you're trying to access is currently unavailable. Try refreshing the page or returning to the homepage."
	},
	"navbar": {
		"overview": "Overview",
		"locker": "Locker",
		"orders": "Orders",
		"return": "Returns",
		"carrier": "Carriers",
		"users": "Users",
		"clients": "Clients",
		"welcome": "Welcome back",
		"company": "Company",
		"profile": "Profile",
		"stores": "Stores",
		"panel": "Dashboard",
		"account": "Account",
		"oxxo_to_oxxo": "OXXO to OXXO",
		"active_routes": "Active Routes",
		"lang": "Language",
		"appearance": "Appearance",
		"integrations": "Integrations",
		"version": "System Version",
		"webhook": "Webhook Settings",
		"myaccount": "My Account",
		"es": "Spanish",
		"en": "English",
		"dark": "Dark Mode",
		"light": "Light Mode",
		"log-package": "Package Audit"
	},
	"month": {
		"Ene": "Jan",
		"Feb": "Feb",
		"Mar": "Mar",
		"Abr": "Apr",
		"May": "May",
		"Jun": "Jun",
		"Jul": "Jul",
		"Ago": "Aug",
		"Sep": "Sep",
		"Oct": "Oct",
		"Nov": "Nov",
		"Dic": "Dec"
	},
	"uploadDocument": {
		"buttonLabel": "Upload clients",
		"popupTitle": "Import your client data",
		"popupSubtitle": "You can upload a file with your client data. If you don't have a file in the correct format yet, you can download a template",
		"popupLink": "by clicking here.",
		"fileUploader": {
			"link": "Click here",
			"clickHere": " to open your file explorer and select the file to upload.",
			"fileSizeLimit": "The file you upload cannot be larger than 10MB."
		},
		"fileUpload": {
			"fileName": "{{this.file.name}}",
			"fileSize": "{{ (this.file.size / 1024 / 1024).toFixed(2) }} MB",
			"progress": "{{this.progress}}%"
		},
		"errors": {
			"fileSizeOver": "It looks like the file you're trying to upload is larger than 10MB. Please check and try again.",
			"invalidFileType": "The file type is not valid. Only Excel files (csv or xls extension) are allowed.",
			"invalidHeaders": "The file doesn't contain the required headers: Name, Lastname, Email, Phone."
		},
		"alert": {
			"text": "Users created",
			"subtitle": "The users have been created"
		}
	},
	"notifications": {
		"title": "Notifications",
		"checkItAll": "Mark all as read",
		"button": "View activity",
		"switchContent": {
			"orders": "Orders",
			"users": "Users",
			"all": "All"
		},
		"empty": {
			"title": "There's nothing here",
			"subtitle": "Here you'll find your notifications for packages or operational issues."
		},
		"items": {
			"packet": "The package",
			"user": "The user",
			"statusTopic": {
				"4": "has logged into",
				"5": "has logged out of",
				"6": "has encountered an error in",
				"7": "has attempted to log in to",
				"8": "has picked up a package at",
				"9": "has delivered a package at",
				"10": "has used the master code at",
				"11": "has picked up an expired package at",
				"14": "has returned a package to the",
				"25": "has been entered into",
				"26": "has been assigned to",
				"27": "is in transit from origin CEDIS",
				"28": "is located at origin CEDIS",
				"29": "is in transit to destination CEDIS",
				"30": "is at destination CEDIS",
				"31": "is in transit to",
				"32": "is being inspected at CEDIS",
				"33": "has been inspected",
				"34": "has been confiscated",
				"35": "is in",
				"36": "has been delivered",
				"37": "has expired at",
				"38": "has been picked up from",
				"39": "has been picked up by the customer",
				"40": "is in the process of being returned to origin",
				"41": "has been returned to the originating customer",
				"42": "has entered legal proceedings"
			},
			"ago": "Ago",
			"minutes": "minutes",
			"hours": "hours",
			"days": "days"
		}
	},
	"systemVersionPage": {
		"title": "System Version",
		"table": {
			"version": "version",
			"date": "date",
			"json": "View Json"
		}
	},
	"estatus": {
		"RECOLECTADO": "Collected",
		"RETURN": "Returned",
		"ENTREGADO": "Delivered",
		"SCHEDULED": "Scheduled",
		"PENDIENTE": "Pending",
		"EXPIRED": "Expired",
		"EXTENDED": "Extended",
		"LOCKER": "In Locker",
		"CANCELED": "Canceled",
		"CANCELED_LOCKER": "Canceled In Locker",
		"CANCELED_PICKED": "Canceled Withdrawn",
		"PASSWORD_EXPIRADO": "Password Expired",
		"ACTIVE": "Active",
		"DESACTIVE": "Deactivated",
		"PackageCollected": "Package Collected",
		"PackageInLocker": "Package in Locker",
		"PackageExpired": "Package Expired",
		"Warehouse": "In Warehouse",
		"ScheduledDoor": "Scheduled Door",
		"OpenDoor": "Open Door",
		"CloseDoor": "Closed Door",
		"FailDoor": "Door Failure",
		"BusyDoor": "Door Occupied",
		"loginSuccess": "Login Success",
		"loginError": "Login Attempt",
		"useMasterCode": "Master Code Use",
		"logout": "Log-out"
	}
}
